import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import MaterielDetail from './materielDetail';

export default function MaterielCard({ materiel }) {
  const [open, setOpen] = React.useState(false);
  // console.log(materiel);

  const etatMateriel = useQuery('etatMateriel', proSDISService.getEtatMateriels, {
    staleTime: 1000 * 60 * 60,
  });

  if (etatMateriel.isLoading) {
    return <Loader />;
  }
  return (
    <Accordion key={materiel.numeroMateriel} onChange={(event, isOpen) => setOpen(isOpen)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#' + etatMateriel.data.etats[materiel.idEtat].couleurFond, color: '#' + etatMateriel.data.etats[materiel.idEtat].couleurTexte }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{materiel.libelle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{open ? <MaterielDetail materiel={materiel} /> : null}</AccordionDetails>
    </Accordion>
  );
}
