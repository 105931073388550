import { get, post } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';

//https://geowebserver.118-vaud.ch/systel-rest/cartographie/sinistre/categories/?icone=true
//https://geowebserver.118-vaud.ch/systel-rest/cartographie/centre/
//https://geowebserver.118-vaud.ch/systel-rest/cartographie/centre/563

//https://geowebserver.118-vaud.ch/systel-rest/cartographie/centre/563/personnels

//https://geowebserver.118-vaud.ch/systel-rest/cartographie/sinistre/familles/?icone=true

const getEtatPersonels = async () => {
  return await get('https://geowebserver.118-vaud.ch/systel-rest/cartographie/specialiste/etats/');
};
const getEtatMateriels = async () => {
  return await get('https://geowebserver.118-vaud.ch/systel-rest/cartographie/materiel/etats/');
};

const getInterventions = async () => {
  return await get('https://geowebserver.118-vaud.ch/systel-rest/cartographie/intervention/');
};

const getAllMateriels = async () => {
  return await get('https://geowebserver.118-vaud.ch/systel-rest/cartographie/materiel/?version=');
};

const getMaterielProchainDepart = async (matId) => {
  return await get(`https://startserver.118-vaud.ch/systel-rest/armabilite/materiel/${matId}/prochainDepart/?ordreFonction=0`);
};
const getEtatDePlanningCentre = async (matId) => {
  return await get(`https://geowebserver.118-vaud.ch/systel-rest/cartographie/centre/${matId}/etats-planning`);
};
const getPersonelCentre = async (matId) => {
  return await get(`https://geowebserver.118-vaud.ch/systel-rest/cartographie/centre/${matId}/personnels`);
};

// async ({ queryKey }) => proSDISService.getAllMateriels()

const getidentify = async () => {
  const detailedToken = await getDetailedToken();
  if (detailedToken) {
    const res = await get('https://startserver.118-vaud.ch/systel-rest/auth/identify/');
    AuthenticationService.setDetailedIdentity(res);
    return res;
  } else {
    AuthenticationService.logout();
    return null;
  }
};

const getDetailedToken = async () => {
  try {
    const auth = AuthenticationService.getCurrentUser();
    console.log(auth);
    const group = auth?.groups[0] ?? 'SDIS%20-%20SPECIALISTE';
    const result = await post(
      'https://keycloak.118-vaud.ch/auth/realms/systel/protocol/openid-connect/token?selectedgroup=' + group,
      'clientId=start&audience=start&grant_type=urn:ietf:params:oauth:grant-type:uma-ticket'
    );

    // console.log(result.access_token);
    // localStorage.setItem('currentUser', user);
    // currentUserSubject.next(result.data);
    AuthenticationService.setDetailedToken(result.access_token);
    return result.access_token;
  } catch (error) {
    return null;
  }
};

export const proSDISService = {
  getInterventions,
  getidentify,
  getDetailedToken,
  getAllMateriels,
  getEtatMateriels,
  getMaterielProchainDepart,
  getEtatDePlanningCentre,
  getPersonelCentre,
};
