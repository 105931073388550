import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { DateUtiles } from 'src/services/dateUtiles';
import MapIcon from '@mui/icons-material/Map';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';

export default function InterventionCard({ intervention }) {
  // console.log(intervention);
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];
  return (
    <Card
      key={intervention.numeroIntervention}
      sx={{ mb: 2, cursor: 'pointer' }}
      onClick={() => {
        history.push(`/${module}/intervention/${intervention.numeroIntervention}`);
      }}
    >
      <CardHeader
        sx={{ pb: 1 }}
        // avatar={<interventionAvatar intervention={intervention} />}
        title={intervention.sinistre.nomSinistre}
        subheader={DateUtiles.toDateTime(intervention.dateDebut)}
      />
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <MapIcon />
              </Paper>
              <Paper elevation={0}>{`${intervention?.lieu?.nomCommune ?? ''} - ${intervention?.lieu?.typeLieu ?? ''} ${
                intervention?.lieu?.nomLieuFormatte ?? ''
              }`}</Paper>
            </Stack>
          </Grid>
          {/* <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <StarsIcon />
              </Paper>
              <Paper elevation={0}>
                {intervention.rank_abbreviation} {intervention.lastname} {intervention.firstname}
              </Paper>
            </Stack>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <Running />
              </Paper>
              <Paper elevation={0}>{intervention.nb_person}</Paper>
              <Paper elevation={0}>
                <LocalShippingIcon />
              </Paper>
              <Paper elevation={0}>{intervention.nb_vhc}</Paper>
            </Stack>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}
