import React from 'react';
import Loader from 'src/shared/defaultLoader';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import InterventionCard from './interventionCard';

export default function Interventions() {
  const { data: data, isLoading: isLoading } = useQuery(['interventions'], async ({ queryKey }) => await proSDISService.getInterventions(), {
    onSuccess: (data) => {
      console.log(data);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  const interventions = data.interventions;

  interventions.sort(function (a, b) {
    return b.dateDebut - a.dateDebut;
  });

  return interventions.map((intervention) => {
    return <InterventionCard key={intervention.numeroIntervention} intervention={intervention} />;
  });
}
