import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './pages/home/home';

import Container from '@mui/material/Container';
import Interventions from './pages/interventions/interventions';
import Materiels from './pages/materiels/materiels';
import Personels from './pages/personels/personels';
import InterventionDetails from './pages/interventions/Details/interventionDetails';

export default function UserContent() {
  let { path } = useRouteMatch();

  return (
    <Container maxWidth="sm" sx={{ p: '4px' }}>
      <Switch>
        <Route path={`${path}/materiels`}>
          <Materiels />
        </Route>
        <Route path={`${path}/personels`}>
          <Personels />
        </Route>
        <Route path={`${path}/intervention/:id`}>
          <InterventionDetails />
        </Route>

        <Route exact path={path}>
          <Interventions />
        </Route>
      </Switch>
    </Container>
  );
}
