import React from 'react';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function PersonelDetail({ centre }) {
  const etatPlanning = useQuery(['etatDePlanning', centre.id], () => proSDISService.getEtatDePlanningCentre(centre.id), {
    staleTime: 1000 * 60 * 60,
  });

  const personels = useQuery(['personelsCentre', centre.id], () => proSDISService.getPersonelCentre(centre.id), {
    staleTime: 1000 * 60 * 60,
  });

  if (personels.isLoading || etatPlanning.isLoading) {
    return <Loader />;
  }

  const personelByEtat = {};
  for (const personel of personels.data.personnels) {
    if (personelByEtat[personel.etatPlanning] == undefined) {
      personelByEtat[personel.etatPlanning] = { personnels: [] };
    }
    personelByEtat[personel.etatPlanning].personnels.push(personel);
  }

  console.log(personelByEtat);

  const etatPlanningCentre = Object.entries(etatPlanning.data.etats).map(([key, value]) => {
    return { id: key, order: value.ordre, value: value };
  });

  etatPlanningCentre.sort(function (a, b) {
    return a.ordre - b.ordre;
  });
  console.log(etatPlanningCentre);

  return etatPlanningCentre.map((etat) => {
    if (!personelByEtat[etat.id]?.personnels) {
      return null;
    }
    return (
      <React.Fragment key={etat.id}>
        <Grid item xs={12} mt={1}>
          <Box
            sx={{
              backgroundColor: '#' + etat.value.couleur,
              textAlign: 'center',
              color: 'primary.contrastText',
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" gutterBottom component="div">
              {etat.value.libelle}
            </Typography>
          </Box>
        </Grid>
        {personelByEtat[etat.id].personnels.map((p, i) => {
          return (
            <Grid key={i} container spacing={1}>
              <Grid item xs={8}>
                {`${p?.nom ?? ''} ${p?.prenom ?? ''}`}
              </Grid>
            </Grid>
          );
        })}
      </React.Fragment>
    );
  });

  // return personels.data.personnels.map((p, i) => {
  //   return (
  //     // <div>
  //     //   {p.codeFctOccupee} - {p?.personnel.nom} {p?.personnel.prenom}
  //     // </div>
  //     <Grid key={i} container spacing={1}>
  //       <Grid item xs={8}>
  //         {`${p?.nom ?? ''} ${p?.prenom ?? ''}`}
  //       </Grid>
  //     </Grid>
  //   );
  // });
}
