import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import PersonelDetail from './personelDetail';

export default function PersonelsCentre({ centre }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Accordion key={centre.id} onChange={(event, isOpen) => setOpen(isOpen)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // sx={{ backgroundColor: '#' + etatMateriel.data.etats[materiel.idEtat].couleurFond, color: '#' + etatMateriel.data.etats[materiel.idEtat].couleurTexte }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{centre.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>{open ? <PersonelDetail centre={centre} /> : null}</AccordionDetails>
    </Accordion>
  );
}
