import React from 'react';
import { proSDISService } from 'src/api/proSDIS';
import PersonelsCentre from './personelsCentre';
import { useQuery } from 'react-query';

export default function Personels() {
  const identity = useQuery('indentity', proSDISService.getidentify, {
    staleTime: 1000 * 60 * 60,
  });

  const myCentre = Object.entries(identity.data.authentification.centres).map(([key, value]) => {
    return { id: key, name: value };
  });

  return myCentre.map((center) => {
    return <PersonelsCentre key={center.id} centre={center} />;
  });
}
