import React from 'react';
import Loader from 'src/shared/defaultLoader';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import InterventionCard from '../interventionCard';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InterventionCentreDetail from './interventionCentreDetail';
import Grid from '@mui/material/Grid';
import Specialiste from './specialistes/specialiste';

export default function InterventionDetails() {
  const { data: data, isLoading: isLoading } = useQuery(['interventions'], async ({ queryKey }) => await proSDISService.getInterventions());

  let { id } = useParams();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {data.interventions.map((intervention) => {
        if (intervention.numeroIntervention === id) {
          const centers = Object.entries(intervention.renforts).map(([key, value]) => {
            return { id: key, value: value };
          });

          console.log(centers);
          let specialises = [];
          if (intervention.specialistes) {
            specialises = Object.entries(intervention.specialistes).map(([key, value]) => {
              return { id: key, value: value };
            });
          }
          console.log(specialises);
          return (
            <React.Fragment key={intervention.numeroIntervention}>
              <InterventionCard intervention={intervention} />

              <Card sx={{ mb: 2 }}>
                <CardHeader sx={{ pb: 1 }} title={'Spécialistes'} />
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {specialises.map((specialiste) => {
                        return <Specialiste key={specialiste.id} specialiste={specialiste.value} />;
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 2 }}>
                <CardHeader sx={{ pb: 1 }} title={'Matériels'} />
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {centers.map((center) => {
                        return <InterventionCentreDetail key={center.id} center={center.value} />;
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </React.Fragment>
          );
        }
      })}
    </>
  );
}
