import React from 'react';
import Box from '@mui/material/Box';
import Loader from 'react-loader-spinner';

export default function DefaultLoader() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
      <Loader className="loader" type="BallTriangle" color="#1976d2" height={100} width={100} />
    </Box>
    // <Container>
    //   <Loader className="loader" type="ThreeDots" color="#00BFFF" height={100} width={100} />
    // </Container>
  );
}
