import api from 'src/api/axios';
// import { get } from 'src/api/api';

import { BehaviorSubject } from 'rxjs';
//import jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

const localStorageCurrentUser = localStorage.getItem('currentUser');
let detailedToken = '';
let detailedIdentity = null;

const currentUserSubject = new BehaviorSubject(localStorageCurrentUser ? JSON.parse(localStorageCurrentUser) : null);
// currentUserSubject.subscribe(async (user) => {
//   if (user) {
//     const response = await get('params/currentUser');
//     console.log(response);
//   }
// });

const registerNewUser = async (userData) => {
  const { user_name, password, email } = userData;
  const result = await api.post('/user/register', { user_name, password, email });
  // console.log(result);
};

const login = async (login, password) => {
  try {
    const result = await api.post('https://mdgate.systel-dc.com/identification', {
      sdis: 'ECA',
      user: login,
      password: password,
      mqtt: true,
      numVersion: '3.6.1_2',
    });
    const user = JSON.stringify(result.data);
    console.log(user);
    localStorage.setItem('currentUser', user);
    currentUserSubject.next(result.data);
    return true;
  } catch (error) {
    return false;
  }
};

function getToken() {
  return localStorage.getItem('currentUser');
}

function getDecodedToken() {
  const currentUser = currentUserSubject.value;
  const token = currentUser?.sdisVO?.access_token;
  if (token) {
    const myDecodedToken = jwtDecode(token);
    console.log(token);
    console.log(myDecodedToken);
    return myDecodedToken;
  } else {
    return null;
  }
}

function getCurrentUser() {
  const userToken = getDecodedToken();
  return userToken;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

function setDetailedIdentity(newDetailedIdentity) {
  detailedIdentity = newDetailedIdentity;
}
function getDetailedIdentity() {
  return detailedIdentity;
}

function setDetailedToken(newDetailedToken) {
  detailedToken = newDetailedToken;
}

function getDetailedToken() {
  return detailedToken;
}

export const AuthenticationService = {
  login,
  logout,
  registerNewUser,
  getToken,
  getDecodedToken,
  getCurrentUser,
  setDetailedToken,
  getDetailedToken,
  setDetailedIdentity,
  getDetailedIdentity,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
