import axios from 'axios';
import { AuthenticationService } from 'src/services/auth';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: 'http://localhost:3333/',
});

api.interceptors.request.use((config) => {
  // const user = JSON.parse(localStorage.getItem('currentUser'));
  const user = AuthenticationService.currentUserValue;

  const detailedToken = AuthenticationService.getDetailedToken();
  if (detailedToken && detailedToken != '') {
    const token = 'Bearer ' + detailedToken;
    config.headers.Authorization = token;
  } else {
    if (user && (user?.sdisVO?.access_token ?? null)) {
      const token = 'Bearer ' + user?.sdisVO?.access_token;
      config.headers.Authorization = token;
    }
  }
  return config;
});

export default api;
export const baseUrl = `${process.env.REACT_APP_API_SERVER}`;
