import { createContext, useContext, useState, useMemo } from 'react';

const UIContext = createContext(null);

export const useUIContext = () => useContext(UIContext);

export const UIContextProvider = ({ children }) => {
  const uiState = {
    isMenuOpen: false,
    appBarTitle: 'mySDIS+',
    memberListCurrentOption: 'ALL',
  };

  const [state, setstate] = useState(uiState);

  const value = useMemo(() => {
    return {
      ...state,
      toggleMenu: () => {
        setstate({ ...state, isMenuOpen: !state.isMenuOpen });
      },
      setAppBarTitle: (newTitle) => {
        setstate({ ...state, appBarTitle: newTitle });
      },
      setMemberListCurrentOption: (newOption) => {
        setstate({ ...state, memberListCurrentOption: newOption });
      },
    };
  }, [state]);

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
// export default UIContext;
