import React from 'react';
import { useQuery } from 'react-query';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import MaterielCard from './materielCard';
import Box from '@mui/material/Box';

export default function Materiels() {
  const identity = useQuery('indentity', proSDISService.getidentify, {
    staleTime: 1000 * 60 * 60,
  });
  const { data, isLoading } = useQuery('materiels', proSDISService.getAllMateriels, {
    staleTime: 1000 * 30,
  });

  if (isLoading || identity.isLoading) {
    return <Loader />;
  }

  const myCentre = Object.entries(identity.data.authentification.centres).map(([key, value]) => {
    return { id: key, name: value };
  });

  return myCentre.map((center) => {
    const centerMat = [];
    for (const matid in data.materiels) {
      const mat = data.materiels[matid];
      if (mat.numeroCentre == center.id) {
        centerMat.push(mat);
      }
    }

    centerMat.sort(function (a, b) {
      return a.ordreDansCentre - b.ordreDansCentre;
    });

    return (
      <React.Fragment key={center.id}>
        <Grid key={center.id} item xs={12} mt={2}>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              textAlign: 'center',
              color: 'primary.contrastText',
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" gutterBottom component="div">
              {center.name}
            </Typography>
          </Box>
        </Grid>
        {centerMat.map((mat) => {
          return <MaterielCard key={mat.numeroMateriel} materiel={mat} />;
        })}
      </React.Fragment>
    );
  });
}
