import React from 'react';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import Grid from '@mui/material/Grid';

export default function MaterielDetail({ materiel }) {
  const prochainDepart = useQuery(['prochainDepart', materiel.numeroMateriel], () => proSDISService.getMaterielProchainDepart(materiel.numeroMateriel), {
    staleTime: 1000 * 30,
  });

  if (prochainDepart.isLoading) {
    return <Loader />;
  }
  // console.log(prochainDepart.data);
  return prochainDepart.data.equipage.map((p, i) => {
    return (
      // <div>
      //   {p.codeFctOccupee} - {p?.personnel.nom} {p?.personnel.prenom}
      // </div>
      <Grid key={i} container spacing={1}>
        <Grid item xs={4}>
          {`${p.codeFctOccupee} :`}
        </Grid>
        <Grid item xs={8}>
          {`${p?.personnel?.nom ?? ''} ${p?.personnel?.prenom ?? ''}`}
        </Grid>
      </Grid>
    );
  });
}
