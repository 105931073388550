import React from 'react';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import Grid from '@mui/material/Grid';

export default function MaterielDetail({ materiel }) {
  const personels = materiel.personnels;
  if (!personels) {
    return null;
  }
  return Object.keys(personels).map((keyName, i) => {
    const p = personels[keyName];
    return (
      <Grid key={i} container spacing={1}>
        <Grid item xs={4}>
          {`${p.codeFctOccuppee} :`}
        </Grid>
        <Grid item xs={8}>
          {`${p?.nom ?? ''} ${p?.prenom ?? ''}`}
        </Grid>
      </Grid>
    );
  });
}
