import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
// import * as jwt from 'jsonwebtoken';
// import { publicKey } from 'src/publickey';
import { jwtDecode } from 'jwt-decode';

function ProtectedRoute({ component: Component, module, ...restOfProps }) {
  const currentUser = AuthenticationService.currentUserValue;
  // console.log(currentUser);
  const token = currentUser?.sdisVO?.access_token;
  let decoded = null;
  if (token) {
    try {
      decoded = jwtDecode(token);
    } catch (error) {
      localStorage.removeItem('currentUser');
    }
  }

  return <Route {...restOfProps} render={(props) => (currentUser && decoded ? <Component {...props} /> : <Redirect to="/" />)} />;
}

export default ProtectedRoute;
