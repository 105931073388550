import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';

export default function Specialiste({ specialiste }) {
  const etatMateriel = useQuery('etatMateriel', proSDISService.getEtatMateriels, {
    staleTime: 1000 * 60 * 60,
  });

  if (etatMateriel.isLoading) {
    return <Loader />;
  }
  return (
    <Accordion>
      <AccordionSummary
        sx={{
          backgroundColor: '#' + etatMateriel.data.etats[specialiste.idEtat].couleurFond,
          color: '#' + etatMateriel.data.etats[specialiste.idEtat].couleurTexte,
        }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`${specialiste.specialite} ${specialiste.prenom} ${specialiste.nom}`}</Typography>
      </AccordionSummary>
    </Accordion>
  );
}
//;
