import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MaterielCard from './materiels/materielCard';

export default function InterventionCentreDetail({ center }) {
  let materiel = [];
  if (center?.materielsEngages ?? false) {
    materiel = Object.entries(center.materielsEngages).map(([key, value]) => {
      return { id: key, value: value };
    });
  }

  return (
    <>
      <Grid key={center.id} item xs={12} mt={2}>
        <Box
          sx={{
            backgroundColor: 'primary.main',
            textAlign: 'center',
            color: 'primary.contrastText',
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" gutterBottom component="div">
            {center.nomCentre}
          </Typography>
        </Box>
      </Grid>
      {materiel.map((mat) => {
        return <MaterielCard key={mat.value.numeroMateriel} materiel={mat.value} />;
      })}
    </>
  );
}
