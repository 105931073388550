import React from 'react';
import Topbar from './components/topbar/topbar';
// import Sidebar from './components/sidebar/sidebar';
import BottomNav from './components/bottomNav/bottomNav';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { UIContextProvider } from './contexts/ui/ui.context';
import { ThemeProvider } from '@mui/material/styles';
import { UserContextProvider } from './contexts/user/user.context';
import theme from './user.theme';
import UserContent from './userContent';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
import { proSDISService } from 'src/api/proSDIS';
import Loader from 'src/shared/defaultLoader';
import { useQuery } from 'react-query';

export default function Admin() {
  let { path } = useRouteMatch();
  const history = useHistory();

  const { data, isLoading, isError } = useQuery('indentity', proSDISService.getidentify, {
    staleTime: 1000 * 60 * 60,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (data === null) {
    history.push('/');
  }

  return (
    <ThemeProvider theme={theme}>
      <UIContextProvider>
        <UserContextProvider>
          <Box sx={{ bgcolor: '#efefef', minHeight: '100vh', flexFlow: 'column', display: 'flex' }}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <Topbar />
              {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Sidebar />
              </Box> */}

              <Switch>
                <Route path={`${path}/map/:id`}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Toolbar />
                    {/* <Map /> */}
                  </Box>
                </Route>
                <Route path={`${path}`}>
                  <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                    <Toolbar />
                    <UserContent />
                  </Box>
                </Route>
              </Switch>
            </Box>
            <Box>
              <BottomNav />
            </Box>
          </Box>
        </UserContextProvider>
      </UIContextProvider>
    </ThemeProvider>
  );
}
